/* *{
    padding: 10px !important;
    background-color: black;
    color: white;
    margin-top: 20px;
} */
.emailInput{
    padding: 20px !important;
}
.text-center{
    margin-top: 50px;
}

.list-unstyled p{
    width: 100%;
    padding: 10px;
}
.mid-text-box{
    margin-bottom: 20px;
}
h5.text-uppercase.email{
    margin-top:10px;
}
.list-unstyled{
     line-height: 35px;
}
.list-unstyled p{
    line-height: 25px;
    text-align: center;
}
.list-unstyled1 p{
    line-height: 25px; 
    width: 100%;
    padding: 10px;
}
