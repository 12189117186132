.z-index {
    z-index: 5;
}

input{
    padding: 5px;
    /* margin: 50px; */
}

label{
     margin: 5px;
    padding: 5px; 
    font-size: large;
}


.reg-container {
    position: relative;
    /* width: 1000px; */
    /* height: 650px; */
    margin: 0 auto;
    padding: 0;
}
.reg-container:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

::selection {
    background: rgba(0,0,0,.2);
}


.reg-container .sixteen.columns {
    width: 940px;
    height:700px;
}
.reg-container .column, .reg-container .columns {
    float: left;
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 15px;
    padding-top: 30px;
}


[class^="icon-"], [class*=" icon-"] {
    display: inline;
    width: auto;
    height: auto;
    line-height: normal;
    vertical-align: baseline;
    background-image: none;
    background-position: 0% 0%;
    background-repeat: repeat;
    margin-top: 0;
}
[class^="icon-"], [class*=" icon-"] {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    text-decoration: inherit;
    -webkit-font-smoothing: antialiased;
    *margin-right: .3em;
}
.icon-contact1 {
    font-family: 'FontAwesome';
    font-size: 20px;
    line-height: 20px;
    text-align: center !important;
    z-index: 6;
    padding-right: 10px;
}
.icon-contact1 {
    color: blueviolet;
}
#ajax-form {
    display: block;
    margin-top: 0em;
    padding: 5px;
}

.contact-wrap {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    background: rgba(0,0,0,.5);
    margin: 10px;
}
.contact-wrap p{
    text-align: center;
}


.just_pattern1 {
    background: -webkit-gradient(radial, center center, 0, center center, 460, from(rgba(0,0,0,0.01)), to(rgba(0,0,0,.9)));
    background: -webkit-radial-gradient(circle, rgba(0,0,0,0.01), rgba(0,0,0,.9));
    background: -moz-radial-gradient(circle, rgba(0,0,0,0.01), rgba(0,0,0,.9));
    background: -ms-radial-gradient(circle, rgba(0,0,0,0.01), rgba(0,0,0,.9));
    background: radial-gradient(circle, rgba(0,0,0,0.01), rgba(0,0,0,.9));
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.contact-Image{
    background-repeat: no-repeat;
    object-fit:cover !important;
}

input{
    border-radius: 10px;
}
textarea{
    border-radius: 10px;
}

button{
    border-radius: 10px !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;

}

@media (max-width: 375px) { 
    .reg-container {
        position: relative;
        /* width: 500px;
        height: 650px; */
        /* margin: 0 auto; */
        padding: 0;
    }
    .reg-container .sixteen.columns {
        width: 500px;
    }
    .reg-container .column, .reg-container .columns {
        float: left;
        display: inline;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    #ajax-form {
        display: block;
        margin-top: 0em;
        padding: 5px;
    }
    
    .contact-wrap {
        position: relative;
        margin: 0 auto;
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        background: rgba(0,0,0,.5);
        margin: 10px;
    }

    .contact-Image{
        background-repeat: no-repeat;
        object-fit:cover !important;
        margin-bottom: 20px;
    }

    button{
        border-radius: 10px !important;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;
    
    }
    textarea{
        border-radius: 10px;
        width: 300px;
    }

    
.reg-container .sixteen.columns {
    width: 350px;
    height: auto;
}
    
}

.textareawidth{
    width: 50%;
}
@media (max-width: 780px) { 
    .textareawidth{
        width: 100% !important;
    }
}