.caption-style-2 {
  list-style-type: none;
  margin: 30px;
  padding: 0px;
}
.caption-style-2 {
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.caption-style-2:hover .caption {
  opacity: 1;
  transform: translateY(-50px);
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  -o-transform: translateY(-50px);
}

.caption-style-2:hover img {
  border: 2px solid white;
  transition: 0.1s ease;
}

.caption-style-2 img {
  margin: 0px;
  padding: 0px;
  z-index: 4;
}

.caption-style-2 .caption {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 300px;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.caption-style-2 .blur {
  background-color: rgba(0, 0, 0, 0.7);
  height: 300px;
  width: 400px;
  position: absolute;
}
.caption-style-2 .caption-text h2 {
  text-transform: uppercase;
  font-size: 24px;
  margin: 5px;
}
.caption-style-2 .caption-text {
  z-index: 8;
  color: #fff;
  position: absolute;
  width: 400px;
  height: 300px;
  text-align: center;
  top: 20px;
  margin: 5px;
}
