/* .section_heading{
    margin: 100px;
} */
.section_heading {
  padding: 20px;
}

.imgEffect {
  --c: white; /* the border color */
  --b: 2px; /* the border thickness*/
  --g: 5px; /* the gap on hover */

  padding: calc(var(--g) + var(--b));
  --_g: #0000 25%, var(--c) 0;
  background: conic-gradient(
        from 180deg at top var(--b) right var(--b),
        var(--_g)
      )
      var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
    conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0
      var(--_i, 200%) / var(--_i, var(--b)) 200% no-repeat;
  transition: 0.3s, background-position 0.3s 0.3s;
  cursor: pointer;
}
.imgEffect:hover {
  --_i: 100%;
  transition: 0.3s, background-size 0.3s 0.3s;
}

img.imgEffect{
  height: 300px;
  width: 400px;
}

.heading_content p {
  font-size: large;
  line-height: 40px;
}

.description-service p{
  font-size: large;
  line-height: 30px;
  padding: 20px 0;
}