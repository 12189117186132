.visionblockService h4 {
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 30px;
}

p {
    text-align: justify;
}

p {
    /* font-family: 'Poppins',sans-serif; */
    /* color: #666; */
    margin: 0;
    padding: 0 0 15px 0;
    line-height: 24px;
}

.serviceImg{
    /* height: 400px;
    width:500px; */
    width: 70%;
}
.servicebannerImg{
    height: 700px;
    width:100%;
}
@media (max-width: 780px) { 
    .serviceImg{
        width:100% !;
    }
    .servicebannerImg{
        height: 100%;
        width:100%;
    }
}
@media (max-width: 990px) { 
    .serviceImg{
        width:100% ;
    }
    .servicebannerImg{
        height: 100%;
        width:100%;
    }
}
