/* navlinks {
    margin-left: 10px;
    display: "flex";
  }
 logo {
    flex-grow: "1";
    cursor: "pointer";
  }*/
  a{
    text-decoration: "none";
    color: #ffffff !important;
    font-size: "20px";
    /* margin-left: 20px; */
    margin: 10px;
    /* background-color: black; */
         background-color:rgba(0,0,0,0.1); 
         background: transparent;
         box-shadow: none;
  }

  img.Headerlogo{
    height: 64px;
    width:80px;
  }
  
  .navlinks .nav_links:hover {
    color:blueviolet !important;
     color: black !important;
     background-color: white;
   padding: 10px;
   
     border-radius: 5px;
    }  

   AppBar{
     background: transparent;
     box-shadow: none;
     background-color:rgba(255, 255, 255, 0.7) !important;
     padding: 10px;
     color: white;
   } 
   .navlinks{
    background: transparent;
    box-shadow: none;
    /* background-color:rgba(0,0,0,0.1); */
    padding: 10px;
    color: white;
    margin-top: 15px;
   }

   Typography{
    background: transparent;
    box-shadow: none;
    background-color:rgba(0,0,0,0.1);
    color: white;
    margin:"5px";
    padding:"5px"
   }
   IconButton{
    background: transparent;
    box-shadow: none;
    background-color:rgba(0,0,0,0.1);
    color: white;
    padding: 10px;
   }
/* 
   a {
    --c: 	white; /* the border color */
    /* --b: 2px;  */
       /* the border thickness*/
    /* --g: 5px;    */
      /* the gap on hover */
     
    /* padding: calc(var(--g) + var(--b));
    --_g: #0000 25%,var(--c) 0;
    background: 
      conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g))
       var(--_i,200%) 0  /200% var(--_i,var(--b))  no-repeat,
      conic-gradient(            at bottom var(--b) left  var(--b),var(--_g))
       0   var(--_i,200%)/var(--_i,var(--b)) 200%  no-repeat;
    transition: .3s, background-position .3s .3s;
    cursor: pointer;
  } */
  
  /* a:hover {
    --_i: 100%;
    transition: .3s, background-size .3s .3s;
  } */

  /* a:hover{
       color: black;
       background-color: white;
  } */

