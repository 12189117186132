/* #contact-us{
    display: inline-flex;
} */

.c {
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  display: inline;
  margin-right: -15px !important;
  margin-left: -15px !important;
}
::after,
::before {
  box-sizing: border-box;
}
.infoList {
  display: flex !important;
}
.contentC {
  padding: 10px;
  font-size: large;
}

.iconC {
  padding: 10px;
}

.c h3 {
  padding: 20px;
}

.labelC3 {
  text-align: initial;
  /* width: 500px; */
}

/* 
   .separator.left-align {
      margin: 0;
      width: 80px;
      position: relative;
      display: block; 
  }  */

/* .separator {
      margin: 10px auto 0px;
      width: 120px;
      position: relative;
  } */

#map iframe {
  width: 100%;
  height: 300px;
  border: solid 1px blueviolet;
}
