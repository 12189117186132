.blog-banner {
  object-fit: cover !important;
  /* position: relative; */
  background-repeat: no-repeat !important;
  width: 100% !important;
}

::selection {
  background: rgba(0, 0, 0, 0.2);
}
.center-grid {
  display: flex;
}

.GridBlogContainer {
  justify-content: center;
  text-align: justify;
}

.blog-heading {
  text-align: center;
  padding: 20px;
  margin: 10px;
}
/* .GridBlogContainer h4{
      text-align: center;
      justify-content: baseline;
  } */

.center-grid img {
  /* height: 300px; */
  width: 100%;
}

/* .reg-container .sixteen.columns { */
  /* width: 940px; */
/* } */
.reg-containerPage .column,
.reg-containerPage .columns {
  float: left;
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 15px;
}
.contact-wrap {
  position: relative;
  /* margin: 0 auto; */
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  background: rgba(0, 0, 0, 0.5);
  /* margin: 10px; */
  margin-bottom: 50%;
}

.reg-containerPage {
  position: relative;
  /* width: 2000px; */
  width: 30%;
  /* height: 650px; */
  /* height: auto; */
  /* margin: 0 auto; */
  /* padding: 0; */
  height: auto !important;
  margin-bottom: 10%;
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
}
.reg-containerPage:after {
  content: "\0020";
  display: block;
  height: auto;
  clear: both;
  visibility: hidden;
}

/* .blog-gif img.left.aos-init.aos-animate {
      text-align: left;
      margin-left: 10%;
      position: relative;
  } */

/* 
  img.leftImage {
      margin-top: 10%;
      width: 50px;
      height: 200px;
  } */

.blog-gif .leftImage {
  /* text-align: initial !important; */
  margin-left: 10% !important;
  margin-top: 30% !important;
  /* width: 50px !important;
      height: 200px !important; */
  /* height: 200px;
      width: 300px; */
}

/* .leftImage {
      height: 300px;
      width: 800px;
  } */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.blogpara.css-jkymtx-MuiPaper-root {
  padding: 30px;
  /* background-color:#f1f1f1 !important; */
  background: rgba(0, 0, 0, 0.2);
  color: black;
  height: 350px;
  margin-top: 5px 0;
  margin-bottom: 50px;
}
.blog-banner img {
  width: 100%;
  height: auto;
  object-fit: cover;
  background-image: url("../../img/Blogs3.avif");
}

h4.MuiTypography-root.MuiTypography-h4.css-5lbw0b-MuiTypography-root {
  margin: 20px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-jkymtx-MuiPaper-root {
  margin-bottom: 50px;
}

.MuiBox-root.css-0 {
  margin-bottom: 50px;
  padding: 50px 0;
}

h6.MuiTypography-root.MuiTypography-h6.side-paragraph.css-2ulfj5-MuiTypography-root {
  margin-left: 30px;
  color: black;
}

h6.side-paragraph {
  margin: 0px;
  color: black;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

/* .css-1anx036 {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: black;
} */
