.imgEffect {
  --c: white; /* the border color */
  --b: 2px; /* the border thickness*/
  --g: 5px; /* the gap on hover */

  padding: calc(var(--g) + var(--b));
  --_g: #0000 25%, var(--c) 0;
  background: conic-gradient(
        from 180deg at top var(--b) right var(--b),
        var(--_g)
      )
      var(--_i, 200%) 0 /200% var(--_i, var(--b)) no-repeat,
    conic-gradient(at bottom var(--b) left var(--b), var(--_g)) 0
      var(--_i, 200%) / var(--_i, var(--b)) 200% no-repeat;
  transition: 0.3s, background-position 0.3s 0.3s;
  cursor: pointer;
}
.imgEffect:hover {
  --_i: 100%;
  transition: 0.3s, background-size 0.3s 0.3s;
}

.icon-norm img {
  width: 100px;
  height: 100px;
}

p.box-text {
  /* margin-left: 50px; */
  text-align: center;
  text-align: justify;
  margin: 10px;
}

h3.box-heading {
  padding: 10px;
  margin: 10px;
}
.descriptions p {
  /* margin: 10px;  */
  padding: 10px;
  text-align: center;
  text-align: justify;
  line-height: 30px;
  font-size: large;
}

.section_headings h4 {
  /* margin: 30px; */
  margin-top: 40px;
  padding: 10px;
  text-align: center;
}

section#about-detail .section_headings h4 span {
  color: blueviolet;
}

#AboutUs_postPage {
  padding: 50px 0;
  /* margin: 10px 0; */
}

.row {
  padding-top: 20px;
}

.content-area-page {
  /* margin-left: 100px; */
  margin-top: 10px;
  padding-top: 30px;
  text-align: center !important;
  text-align: justify;
}

.content-area-page p {
  line-height: 25px;
  font-size: large;
}

img.img_sect.aos-init.aos-animate {
  height: 400px;
  width: 100%;
  /* margin-left: 100px; */
}

img.aboutBottom {
  width: 85%;
  height: 430px;
}
