.visionblock h4 {
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  font-size: 30px;
}

p {
  text-align: justify;
}

p {
  /* font-family: 'Poppins',sans-serif; */
  /* color: #666; */
  margin: 0;
  padding: 0 0 15px 0;
  line-height: 24px;
}

#portfolio {
  padding-top: 50px;
  padding-bottom: 100px;
  /* margin-bottom: 50px; */
}

body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
